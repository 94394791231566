import React from 'react'
import "../Header/header.css"
const Header = () => {
  return (
    <div className='termsheader-Section'>
      <div className='termsheader'>
      <h1 className='termsheaderh1'>PRIVACY AND POLICIES</h1>
      <div className='updateTime '>
        <p  className='updateTimep'>Updated July 4, 2023</p>
      </div>
      </div>
    </div>
  )
}

export default Header
